"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeForgotPasswordComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var change_forgot_password_1 = require("../../../api/calls/user/change_forgot_password");
var react_router_dom_1 = require("react-router-dom");
var local_storage_names_1 = require("../../../constants/local_storage_names");
var user_1 = require("../../../reducers/slices/user");
var routes_1 = require("../../../constants/routes");
var get_user_1 = require("../../../api/calls/user/get_user");
var ChangeForgotPasswordComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    //reset_token
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var reset_token = params.reset_token;
    var searchParams = (0, react_router_dom_1.useSearchParams)()[0];
    var reset_token_search = searchParams.get("reset_token");
    var _a = (0, react_1.useState)(""), password = _a[0], setPassword = _a[1];
    var _b = (0, react_1.useState)(""), confirmPassword = _b[0], setConfirmPassword = _b[1];
    var _c = (0, react_1.useState)(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = (0, react_1.useState)(""), passwordMessage = _d[0], setPasswordMessage = _d[1];
    var _e = (0, react_1.useState)(""), confirmPasswordMessage = _e[0], setConfirmPasswordMessage = _e[1];
    var validate = function () {
        var messageCount = 0;
        setPasswordMessage("");
        if (!password) {
            messageCount++;
            setPasswordMessage("New password is required");
        }
        if (!confirmPassword) {
            messageCount++;
            setConfirmPasswordMessage("Confirm password is required");
        }
        return messageCount === 0 ? true : false;
    };
    return (react_1.default.createElement("div", { className: "flex  justify-center" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4 w-96" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Change Forgot Password", size: "xlarge", bold: true, textColor: "text-black", center: true }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You can now change your password below.", size: "small", textColor: "text-black", center: true }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "New Password", value: password, onChange: function (v) {
                    setPassword(v);
                }, errorText: passwordMessage }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Confirm New Password", value: confirmPassword, onChange: function (v) {
                    setConfirmPassword(v);
                }, errorText: confirmPasswordMessage }),
            react_1.default.createElement("div", { className: "flex gap-4 justify-center" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Change Password", loading: isLoading, backgroundColor: "bg-success", size: "small", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (validate()) {
                                setIsLoading(true);
                                (0, change_forgot_password_1.change_forgot_password)({
                                    new_password: password,
                                    reset_token: decodeURIComponent(reset_token),
                                }).then(function (changePasswordResponse) {
                                    setIsLoading(false);
                                    if (changePasswordResponse.success) {
                                        (0, get_user_1.get_user)({
                                            user_entity_id: changePasswordResponse.content.user.entity_id,
                                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                                            var usr;
                                            return __generator(this, function (_a) {
                                                setIsLoading(false);
                                                if (response.success) {
                                                    usr = {
                                                        user: __assign(__assign({}, changePasswordResponse.content.user), response.content),
                                                        token: changePasswordResponse.content.token,
                                                    };
                                                    dispatch((0, user_1.setUser)(usr.user));
                                                    dispatch((0, user_1.setUserToken)(usr.token));
                                                    if (localStorage.getItem(local_storage_names_1.USER_LOCAL_STORAGE)) {
                                                        localStorage.setItem(local_storage_names_1.USER_LOCAL_STORAGE, JSON.stringify(response.content));
                                                    }
                                                    navigate(routes_1.AUCTIONS_ROUTE);
                                                    dispatch((0, main_1.setMessageModal)({
                                                        title: "Successfully changed!",
                                                        messages: ["You have successfully changed your password, you may proceed."],
                                                    }));
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while retrieving user's details!", messages: [response.message] }));
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); });
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while changing password!", messages: [changePasswordResponse.message] }));
                                    }
                                });
                            }
                            return [2 /*return*/];
                        });
                    }); } })))));
};
exports.ChangeForgotPasswordComponent = ChangeForgotPasswordComponent;
