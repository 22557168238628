"use strict";
// NB: Start all routes must follow:
// - forward slash at the beginning
// - lowercase words
// - using dashes(hyphens) for multiple words
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET_PASSWORD_ROUTE = exports.PRIVACY_POLICY_ROUTE = exports.TERMS_AND_CONDITIONS_ROUTE = exports.CONTACT_ROUTE = exports.AUCTION_COMPLETE_ROUTE = exports.AUCTION_LIVE_ROUTE = exports.AUCTION_DETAILS_ROUTE = exports.AUCTIONS_ROUTE = exports.LOT_COMPLETE_DETAILS_ROUTE = exports.LOT_DETAILS_ROUTE = exports.LIVE_ROUTE = void 0;
// ENTRY ROUTES >
exports.LIVE_ROUTE = "/live";
exports.LOT_DETAILS_ROUTE = "/lot/details";
exports.LOT_COMPLETE_DETAILS_ROUTE = "/lot/complete";
exports.AUCTIONS_ROUTE = "/auctions";
exports.AUCTION_DETAILS_ROUTE = "/auction/details";
exports.AUCTION_LIVE_ROUTE = "/auction/live";
exports.AUCTION_COMPLETE_ROUTE = "/auction/complete";
exports.CONTACT_ROUTE = "/contact";
exports.TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
exports.PRIVACY_POLICY_ROUTE = "/privacy-policy";
exports.RESET_PASSWORD_ROUTE = "/reset_password";
