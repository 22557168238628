"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objToQueryString = void 0;
var objToQueryString = function (obj) {
    // the object could be null
    if (Object.keys(obj !== null && obj !== void 0 ? obj : {}).length === 0) {
        return "";
    }
    // also prepend the ?
    return ("?" +
        Object.keys(obj)
            .map(function (key) { return key + "=" + obj[key]; })
            .join("&"));
};
exports.objToQueryString = objToQueryString;
