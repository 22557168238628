"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogInComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var sign_in_thunk_1 = require("../../../reducers/slices/thunks/sign_in_thunk");
var user_1 = require("../../../reducers/slices/user");
var main_1 = require("../../../reducers/slices/main");
var sign_up_1 = require("../sign_up");
var forgot_password_1 = require("./forgot_password");
var LogInComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _a.user, isLoading = _a.isLoading;
    var _b = (0, react_1.useState)(""), emailMessage = _b[0], setEmailMessage = _b[1];
    var _c = (0, react_1.useState)(""), passwordMessage = _c[0], setPasswordMessage = _c[1];
    var onControlChange = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var messageCount = 0;
        setEmailMessage("");
        setPasswordMessage("");
        if (!user.email) {
            messageCount++;
            setEmailMessage("Email is required");
        }
        if (!user.password) {
            messageCount++;
            setPasswordMessage("Password is required");
        }
        return messageCount === 0 ? true : false;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 h-full justify-center" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Log In", size: "2xlarge", bold: true, textColor: "text-black" }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: user.email, onChange: function (v) {
                        onControlChange("email", v);
                    }, errorText: emailMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", type: "password", value: user.password, onChange: function (v) {
                        onControlChange("password", v);
                    }, errorText: passwordMessage })),
            react_1.default.createElement("div", { className: "flex justify-between items-center" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Remember me?", value: user.rememberMe, onChange: function (v) {
                        onControlChange("rememberMe", v);
                    } }),
                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Forgot Password?", size: "small", onClick: function () {
                        dispatch((0, main_1.setDrawer)({
                            open: true,
                            content: react_1.default.createElement(forgot_password_1.ForgotPasswordComponent, null),
                        }));
                    } }))),
        react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Log In", loading: isLoading, size: "small", onClick: function () {
                    if (validate()) {
                        dispatch((0, sign_in_thunk_1.sign_in_thunk)());
                    }
                }, backgroundColor: "bg-primary" }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Sign Up", size: "small", onClick: function () {
                    dispatch((0, main_1.setDrawer)({
                        open: true,
                        content: react_1.default.createElement(sign_up_1.SignUpComponent, null),
                    }));
                }, backgroundColor: "bg-success" }))));
};
exports.LogInComponent = LogInComponent;
