"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountdownComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var remaining_time_1 = require("../../../../api/calls/auction/remaining_time");
var countdown_1 = require("../../../../reducers/slices/countdown");
var CountdownComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var randomRefresh = (0, react_redux_1.useSelector)(function (state) { return state.countdownState; }).randomRefresh;
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var selectedAutomatedLot = (0, react_redux_1.useSelector)(function (state) { return state.lotState; }).selectedAutomatedLot;
    var _a = (0, react_1.useState)("00:00:00:00"), remainingTime = _a[0], setRemainingTime = _a[1];
    var interval = react_1.default.useRef();
    var updateRemainingTime = function (rt) {
        if (rt) {
            var _a = rt.split(":").map(Number), days = _a[0], hours = _a[1], minutes = _a[2], seconds = _a[3];
            var remainingSeconds_1 = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
            if (interval.current) {
                clearInterval(interval.current);
            }
            // set timer
            interval.current = setInterval(function () {
                // Check if the countdown is complete
                if (remainingSeconds_1 <= 0) {
                    clearInterval(interval.current);
                    console.log("Countdown complete!");
                }
                else {
                    // Decrement remaining seconds
                    remainingSeconds_1--;
                    // Calculate remaining time components
                    var remainingDays = Math.floor(remainingSeconds_1 / (24 * 60 * 60));
                    var remainingHours = Math.floor((remainingSeconds_1 % (24 * 60 * 60)) / (60 * 60));
                    var remainingMinutes = Math.floor((remainingSeconds_1 % (60 * 60)) / 60);
                    var remainingSecondsOutput = remainingSeconds_1 % 60;
                    // Display the remaining time
                    setRemainingTime("".concat(remainingDays.toString().padStart(2, "0"), ":").concat(remainingHours.toString().padStart(2, "0"), ":").concat(remainingMinutes
                        .toString()
                        .padStart(2, "0"), ":").concat(remainingSecondsOutput.toString().padStart(2, "0")));
                }
            }, 1000); // Update every second
        }
    };
    (0, react_1.useEffect)(function () {
        return function () {
            clearInterval(interval.current);
        };
    }, []);
    (0, react_1.useEffect)(function () {
        function ev() {
            console.log("focus");
            dispatch((0, countdown_1.setRandomRefresh)(doshx_controls_web_1.Utilities.randomAlphanumeric(10)));
        }
        window.addEventListener("focus", ev);
        return function () {
            window.removeEventListener("focus", ev);
        };
        // Update the count down every 1 second
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        switch (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.status) {
            case "In-Progress":
                (0, remaining_time_1.remaining_time)({
                    auction_entity_id: liveAuction.entity_id,
                }).then(function (response) {
                    if (response.success) {
                        var rt = response.content.remaining_time;
                        setRemainingTime(rt);
                        updateRemainingTime(rt);
                        // setRemainingTime(response.content);
                    }
                });
                break;
            case "Complete":
                //Only if this is a soft closing auction.
                if ((liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.soft_closing) && ((_a = liveAuction.soft_closing) === null || _a === void 0 ? void 0 : _a.enabled)) {
                    if (selectedAutomatedLot === null || selectedAutomatedLot === void 0 ? void 0 : selectedAutomatedLot.entity_id) {
                        console.log("selectedAutomatedLot._id", selectedAutomatedLot.entity_id);
                        // get_lot_remaining_time({
                        //   lot_id: selectedAutomatedLot.entity_id,
                        // }).then((response) => {
                        //   if (response.success) {
                        //     const rt = response.content;
                        //     setRemainingTime(rt);
                        //     updateRemainingTime(rt);
                        //     // setRemainingTime(response.content);
                        //   }
                        // });
                    }
                }
                break;
        }
    }, [liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.status, selectedAutomatedLot === null || selectedAutomatedLot === void 0 ? void 0 : selectedAutomatedLot.entity_id, randomRefresh]);
    return (react_1.default.createElement("div", { className: "flex justify-center" },
        react_1.default.createElement("div", { className: "text-black text-2xl font-bold animate-pulse" },
            "Remaining Time: ",
            remainingTime)));
};
exports.CountdownComponent = CountdownComponent;
