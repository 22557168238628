"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterControl = void 0;
var react_1 = __importStar(require("react"));
var container_1 = require("./container");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var subscribe_1 = require("../api/calls/newsletter/subscribe");
var react_redux_1 = require("react-redux");
var main_1 = require("../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../constants/routes");
var parseJwt_1 = require("../helpers/parseJwt");
var logos_config_1 = require("../../logos.config");
var get_social_media_1 = require("../api/calls/client/get_social_media");
var pro_duotone_svg_icons_1 = require("@fortawesome/pro-duotone-svg-icons");
var FooterControl = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var _b = (0, react_1.useState)(false), isBusy = _b[0], setIsBusy = _b[1];
    var _c = (0, react_1.useState)(""), emailAddress = _c[0], setEmailAddress = _c[1];
    var _d = (0, react_1.useState)(""), emailAddressError = _d[0], setEmailAddressError = _d[1];
    // const socialMedia = [
    //   { icon: faFacebook, link: "https://www.facebook.com/ChantLab?mibextid=ZbWKwL" },
    //   { icon: faYoutube, link: "https://www.youtube.com/@ChantLab-ro1jc" },
    //   { icon: faLinkedin, link: "https://www.linkedin.com/company/chant-lab/" },
    //   // { icon: faInstagram, link: "https://www.instagram.com/" },
    // ];
    var _e = (0, react_1.useState)([]), socialMedia = _e[0], setSocialMedia = _e[1];
    var quickLinks = [
        {
            title: "Contact us",
            onClick: function () {
                navigate(routes_1.CONTACT_ROUTE);
            },
        },
        {
            title: "Terms & Conditions",
            onClick: function () {
                navigate(routes_1.TERMS_AND_CONDITIONS_ROUTE);
            },
        },
        {
            title: "Privacy Policy",
            onClick: function () {
                navigate(routes_1.PRIVACY_POLICY_ROUTE);
            },
        },
    ];
    (0, react_1.useEffect)(function () {
        (0, get_social_media_1.get_social_media)({ client_entity_id: client_entity_id }).then(function (response) {
            if (response.success) {
                setSocialMedia(response.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    }, []);
    var determineIcon = function (icon) {
        switch (icon) {
            case "facebook":
                return free_brands_svg_icons_1.faFacebook;
            case "youtube":
                return free_brands_svg_icons_1.faYoutube;
            case "linkedin":
                return free_brands_svg_icons_1.faLinkedin;
            case "instagram":
                return free_brands_svg_icons_1.faInstagram;
            case "twitter":
                return free_brands_svg_icons_1.faTwitter;
            case "tiktok":
                return free_brands_svg_icons_1.faTiktok;
            case "whatsapp":
                return free_brands_svg_icons_1.faWhatsapp;
            default:
                return pro_duotone_svg_icons_1.faNotEqual;
        }
    };
    return (react_1.default.createElement("footer", { className: "bg-secondary text-white" },
        react_1.default.createElement(container_1.ContainerControl, { className: "py-4" },
            react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-3 gap-4" },
                react_1.default.createElement("div", { className: "flex flex-col gap-4 items-center lg:items-start" },
                    react_1.default.createElement("div", { className: "w-48 rounded-md overflow-clip p-4\n                ".concat(logos_config_1.logos.light.background) },
                        react_1.default.createElement("img", { src: logos_config_1.logos.light.img, className: "w-full object-contain" })),
                    react_1.default.createElement("div", { className: "flex flex-col lg:pl-[26px] gap-4 items-center" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Social Media", size: "xlarge", textColor: "text-white", bold: true }),
                        react_1.default.createElement("div", { className: "flex flex-row gap-4" }, socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.map(function (item, index) {
                            return (react_1.default.createElement("span", { key: "social-media-".concat(index), className: "text-2xl hover:scale-110 cursor-pointer transition-all", onClick: function () {
                                    window.open(item.url, "_blank");
                                } },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: determineIcon(item.platform) })));
                        })))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4 items-center lg:items-start" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Quick Links", size: "xlarge", textColor: "text-white", bold: true }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-2" }, quickLinks.map(function (item, index) {
                        return (react_1.default.createElement("div", { key: "quick-link-".concat(index), className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: item.title, textColor: "text-white", onClick: item.onClick })));
                    }))),
                react_1.default.createElement("div", { className: "flex flex-col gap-4 items-center lg:items-stretch" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Newsletter", size: "xlarge", textColor: "text-white", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Subscribe to get our latest updates!", size: "medium", textColor: "text-white" }),
                    react_1.default.createElement("div", { className: "flex flex-row gap-4 flex-1" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { textColor: "text-white", borderColor: "border-white", value: emailAddress, onChange: function (v) {
                                    setEmailAddress(v);
                                }, errorText: emailAddressError, errorTextColor: "text-white" })),
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Subscribe", backgroundColor: "bg-white", borderColor: "border-white", textColor: "text-black", loading: isBusy, onClick: function () {
                                setEmailAddressError("");
                                if (emailAddress && emailAddress.length > 0 && emailAddress.includes("@") && emailAddress.includes(".")) {
                                    setIsBusy(true);
                                    (0, subscribe_1.subscribe)({
                                        email_address: emailAddress,
                                        client_entity_id: client_entity_id,
                                    }).then(function (response) {
                                        setIsBusy(false);
                                        if (response.success) {
                                            setEmailAddress("");
                                            dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Successfully subscribed!"] }));
                                        }
                                        else {
                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                        }
                                    });
                                }
                                else {
                                    setEmailAddressError("Please enter a valid email address.");
                                }
                            } })),
                    react_1.default.createElement("div", { className: "flex gap-1" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "By subscribing to our newsletter, you agree to our ", size: "small", textColor: "text-white" }),
                        react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Privacy Policy.", textColor: "text-white", size: "small", onClick: function () {
                                navigate(routes_1.PRIVACY_POLICY_ROUTE);
                            } })))),
            react_1.default.createElement("div", { className: "flex justify-center lg:justify-start" },
                react_1.default.createElement(doshx_controls_web_1.CopyrightControl, { backgroundColor: "bg-transparent", textColor: "text-white", company: "Chant Lab" })))));
};
exports.FooterControl = FooterControl;
