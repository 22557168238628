"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setClientToken = exports.setUserToken = exports.setUser = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var devMode = process.env.NODE_ENV !== "production";
var initialState = {
    user: {
        entity_id: undefined,
        //TODO: Add the rest of the user fields.
        email: devMode ? "fred@doshex.com" : "",
        password: devMode ? "1234" : "",
        name: devMode ? "Fred" : "",
        surname: devMode ? "Moller" : "",
        confirmPassword: devMode ? "1234" : "",
        cell_phone: {
            calling_code: devMode ? "+27" : "",
            country_code: devMode ? "ZA" : "",
            number: devMode ? "0123456789" : "",
        },
        rememberMe: true,
        agrees_terms_and_conditions: false,
        get_communication: false,
    },
    userToken: undefined,
    clientToken: undefined,
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "console",
    initialState: initialState,
    reducers: {
        setUser: function (state, action) {
            state.user = action.payload;
        },
        setUserToken: function (state, action) {
            state.userToken = action.payload;
        },
        setClientToken: function (state, action) {
            state.clientToken = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
exports.setUser = (_a = slice.actions, _a.setUser), exports.setUserToken = _a.setUserToken, exports.setClientToken = _a.setClientToken, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;
