"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviousCurrentNext = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var socket_1 = require("../socket");
var main_1 = require("../reducers/slices/main");
var manual_previous_current_next_1 = require("../api/calls/lot/manual_previous_current_next");
var PreviousCurrentNext = function (props) {
    var _a, _b;
    var currentLotChanged = props.currentLotChanged;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_1.useState)(null), pcn = _c[0], setPcn = _c[1];
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }), liveAuction = _d.liveAuction, isAuctionCompleted = _d.isAuctionCompleted;
    (0, react_1.useEffect)(function () {
        if (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id) {
            socket_1.socket.off("currentLot");
            socket_1.socket.on("currentLot", function (data) {
                var current = data.current;
                console.log("currentLot", current);
                getPCN();
            });
            getPCN();
        }
    }, [liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id]);
    function getPCN() {
        (0, manual_previous_current_next_1.manual_previous_current_next)({
            auction_entity_id: liveAuction.entity_id,
        }).then(function (response) {
            if (response.success) {
                setPcn(response.content);
                console.log("response.content.current.entity_id", response.content.current.entity_id);
                socket_1.socket.emit("joinLotPipe", response.content.current.entity_id);
                currentLotChanged(response.content.current);
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Error",
                    messages: [response.message],
                }));
            }
        });
    }
    return pcn ? (react_1.default.createElement("div", { className: "grid grid-cols-7 border-4 border-blue-500 rounded-xl overflow-clip h-full" },
        react_1.default.createElement("div", { className: "flex flex-col col-span-2\n          ".concat(((_a = pcn.previous) === null || _a === void 0 ? void 0 : _a.entity_id) && "", "\n        "), onClick: function () {
                // manualSetCurrent(pcn.previous?.entity_id);
            } },
            react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-blue-500 pb-1 text-white" }, "PREVIOUS"),
            pcn.previous ? ( //
            react_1.default.createElement("div", { className: "flex-1 flex flex-row" },
                react_1.default.createElement("div", { className: "w-32 h-32" },
                    react_1.default.createElement("img", { className: "w-full h-full object-cover", src: pcn.previous.images && pcn.previous.images[0].uploaded_file_url })),
                react_1.default.createElement("div", { className: "flex-1 p-2 flex flex-col items-center justify-center" },
                    react_1.default.createElement("div", { className: "flex gap-1 items-center" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag }),
                        react_1.default.createElement("span", { className: "font-bold text-md" }, pcn.previous.lot_number)),
                    react_1.default.createElement("div", null, pcn.previous.title)))) : (react_1.default.createElement("div", { className: "flex-1 flex items-center justify-center bg-red-300" }, "No Previous Lot"))),
        react_1.default.createElement("div", { className: "flex flex-col col-span-3" },
            react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-blue-500 pb-1 text-white" }, "CURRENT"),
            pcn.current ? ( //
            react_1.default.createElement("div", { className: "flex-1 flex flex-row border-l-4 border-r-4 border-x-blue-500" },
                react_1.default.createElement("div", { className: "w-32 h-32" },
                    react_1.default.createElement("img", { className: "w-full h-full object-cover", src: pcn.current.images && pcn.current.images[0].uploaded_file_url })),
                react_1.default.createElement("div", { className: "flex-1 flex flex-col items-center justify-center h-full" },
                    react_1.default.createElement("div", { className: "flex flex-col items-center py-2" },
                        react_1.default.createElement("div", { className: "flex gap-1 items-center" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag }),
                            react_1.default.createElement("span", { className: "font-bold text-md" }, pcn.current.lot_number)),
                        react_1.default.createElement("div", null, pcn.current.title))))) : ("No Current Lot")),
        react_1.default.createElement("div", { className: "flex flex-col col-span-2\n          ".concat(((_b = pcn.next) === null || _b === void 0 ? void 0 : _b.entity_id) && "", "\n        "), onClick: function () {
                var _a;
                if ((_a = pcn.next) === null || _a === void 0 ? void 0 : _a.entity_id) {
                    // manualSetCurrent(pcn.next.entity_id);
                }
            } },
            react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-blue-500 pb-1 text-white" }, "NEXT"),
            pcn.next ? ( //
            react_1.default.createElement("div", { className: "flex-1 flex flex-row" },
                react_1.default.createElement("div", { className: "w-32 h-32" },
                    react_1.default.createElement("img", { className: "w-full h-full object-cover", src: pcn.next.images && pcn.next.images[0].uploaded_file_url })),
                react_1.default.createElement("div", { className: "flex-1 p-2 flex flex-col items-center justify-center" },
                    react_1.default.createElement("div", { className: "flex gap-1 items-center" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag }),
                        react_1.default.createElement("span", { className: "font-bold text-md" }, pcn.next.lot_number)),
                    react_1.default.createElement("div", null, pcn.next.title)))) : (react_1.default.createElement("div", { className: "flex-1 flex items-center justify-center bg-red-300" }, "No Next Lot"))))) : (react_1.default.createElement("div", { className: "grid grid-cols-3" }, "No PCN detected."));
};
exports.PreviousCurrentNext = PreviousCurrentNext;
