"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var colors_config_1 = require("../../../colors.config");
var contact_us_1 = require("../../api/calls/contact_us");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var get_configuration_1 = require("../../api/calls/configuration/get_configuration");
var parseJwt_1 = require("../../helpers/parseJwt");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var ContactPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var _b = (0, react_1.useState)(""), name = _b[0], setName = _b[1];
    var _c = (0, react_1.useState)(""), email = _c[0], setEmail = _c[1];
    var _d = (0, react_1.useState)("other"), queryType = _d[0], setQueryType = _d[1];
    var _e = (0, react_1.useState)(""), subject = _e[0], setSubject = _e[1];
    var _f = (0, react_1.useState)(""), message = _f[0], setMessage = _f[1];
    var _g = (0, react_1.useState)(""), nameError = _g[0], setNameError = _g[1];
    var _h = (0, react_1.useState)(""), emailError = _h[0], setEmailError = _h[1];
    var _j = (0, react_1.useState)(""), queryTypeError = _j[0], setQueryTypeError = _j[1];
    var _k = (0, react_1.useState)(""), subjectError = _k[0], setSubjectError = _k[1];
    var _l = (0, react_1.useState)(""), messageError = _l[0], setMessageError = _l[1];
    var _m = (0, react_1.useState)({}), contacts = _m[0], setContacts = _m[1];
    var _o = (0, react_1.useState)(false), isLoading = _o[0], setIsLoading = _o[1];
    (0, react_1.useEffect)(function () {
        //
    }, []);
    (0, react_1.useEffect)(function () {
        (0, get_configuration_1.get_configuration)({
            client_entity_id: client_entity_id,
        }).then(function (response) {
            if (response) {
                setContacts(response.content.contacts);
            }
        });
    }, []);
    var validate = function () {
        var messageCount = 0;
        var errors = {
            name: "",
            email: "",
            queryType: "",
            subject: "",
            message: "",
        };
        if (name.length === 0) {
            messageCount++;
            errors.name = "Name is required";
        }
        if (email.length === 0) {
            messageCount++;
            errors.email = "Email is required";
        }
        if (queryType.length === 0) {
            messageCount++;
            errors.queryType = "Query Type is required";
        }
        if (subject.length === 0) {
            messageCount++;
            errors.subject = "Subject is required";
        }
        if (message.length === 0) {
            messageCount++;
            errors.message = "Message is required";
        }
        setNameError(errors.name);
        setEmailError(errors.email);
        setQueryTypeError(errors.queryType);
        setSubjectError(errors.subject);
        setMessageError(errors.message);
        return messageCount === 0;
    };
    return (react_1.default.createElement("div", { className: "flex justify-center" },
        react_1.default.createElement("div", { className: "container w-96 bg-white rounded-lg shadow-lg shadow-shadows flex flex-col" },
            react_1.default.createElement("div", { className: "flex justify-center pt-8" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Contact Us", textColor: "text-black", size: "3xlarge", bold: true })),
            react_1.default.createElement("div", { className: "p-8 divide-y" },
                contacts && (react_1.default.createElement("div", { className: "pb-8" },
                    (contacts === null || contacts === void 0 ? void 0 : contacts.cell_number) && (react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                        react_1.default.createElement("div", { className: "text-black text-2xl w-10" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPhone })),
                        react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Enquire via Phone", textColor: "text-black", size: "large", onClick: function () {
                                window.open("tel:".concat(contacts === null || contacts === void 0 ? void 0 : contacts.cell_number));
                            } }))),
                    (contacts === null || contacts === void 0 ? void 0 : contacts.whatsapp) && (react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                        react_1.default.createElement("div", { className: "text-whatsapp text-3xl w-10" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faWhatsapp })),
                        react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Enquire via Whatsapp", textColor: "text-black", size: "large", onClick: function () {
                                window.open("https://wa.me/".concat(contacts === null || contacts === void 0 ? void 0 : contacts.whatsapp, "?text=Hello"));
                            } }))),
                    (contacts === null || contacts === void 0 ? void 0 : contacts.email) && (react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                        react_1.default.createElement("div", { className: "text-black text-2xl w-10" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faEnvelope })),
                        react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Enquire via Email", textColor: "text-black", size: "large", onClick: function () {
                                window.open("mailto:".concat(contacts === null || contacts === void 0 ? void 0 : contacts.email));
                            } }))))),
                react_1.default.createElement("div", { className: "text-sm text-center py-4" }, "... or submit your query below ..."),
                react_1.default.createElement("div", { className: "pt-8 flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: name, onChange: function (v) {
                            setName(v);
                        }, errorText: nameError }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: email, onChange: function (v) {
                            setEmail(v);
                        }, errorText: emailError }),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Query Type", value: queryType, options: [
                            { label: "Need Verification", value: "need_verification" },
                            { label: "Other", value: "other" },
                        ], onChange: function (v) {
                            setQueryType(v);
                        }, errorText: queryTypeError }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Subject", value: subject, onChange: function (v) {
                            setSubject(v);
                        }, errorText: subjectError }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Message", lines: 8, value: message, onChange: function (v) {
                            setMessage(v);
                        }, errorText: messageError }),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit Query", loading: isLoading, backgroundColor: "bg-primary", onClick: function () {
                                if (validate()) {
                                    setIsLoading(true);
                                    (0, contact_us_1.contact_us)({
                                        client_entity_id: client_entity_id,
                                        name: name,
                                        email: email,
                                        query_type: queryType,
                                        subject: subject,
                                        message: message,
                                    }).then(function (response) {
                                        setIsLoading(false);
                                        if (response.success) {
                                            setName("");
                                            setEmail("");
                                            setQueryType("other");
                                            setSubject("");
                                            setMessage("");
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Success",
                                                messages: ["Your query has been submitted successfully"],
                                            }));
                                        }
                                        else {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Error",
                                                messages: [response.message],
                                            }));
                                        }
                                    });
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Success",
                                        messages: ["Your query has been submitted successfully"],
                                    }));
                                }
                            } }))))),
        react_1.default.createElement("div", { className: "absolute top-0 w-full -z-10" },
            react_1.default.createElement("div", { className: "h-72 bg-secondary" }),
            react_1.default.createElement("div", { className: "-mt-[1px]" },
                react_1.default.createElement("svg", { viewBox: "0 0 20 1.5", xmlns: "http://www.w3.org/2000/svg" },
                    react_1.default.createElement("path", { d: "M 0 0 L 20 0 Q 10 3 0 0", fill: colors_config_1.colors.secondary }))))));
};
exports.ContactPage = ContactPage;
