"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRandomRefresh = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    randomRefresh: null,
};
var slice = (0, toolkit_1.createSlice)({
    name: "countdown",
    initialState: initialState,
    reducers: {
        setRandomRefresh: function (state, action) {
            var _a;
            state.randomRefresh = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setRandomRefresh = slice.actions.setRandomRefresh;
exports.default = slice.reducer;
