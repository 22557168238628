"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setHighestBids = exports.setBids = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    bids: [],
    highestBids: {
    // lot_entity_id: value
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "bid",
    initialState: initialState,
    reducers: {
        setBids: function (state, action) {
            var _a;
            state.bids = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setHighestBids: function (state, action) {
            var _a;
            state.highestBids = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setBids = (_a = slice.actions, _a.setBids), exports.setHighestBids = _a.setHighestBids;
exports.default = slice.reducer;
