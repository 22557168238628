"use strict";
// import React, { createContext } from "react";
// import { io, Socket } from "socket.io-client";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.socket = void 0;
// export const socket = io(process.env.AUCTION_PLATFORM_API_URL ?? "", {
//   transports: ["websocket"],
//   // autoConnect: false,
//   autoConnect: true,
//   // reconnection: true,
// });
// // socket.connect();
// socket.on("connect", function (x) {
//   console.log("connection", x);
// });
// socket.on("connect_error", function (err) {
//   console.log("connect_error", err);
// });
// socket.on("connect_failed", function (err) {
//   console.log("connect_failed", err);
// });
// export const SocketContext = createContext<Socket>(null);
var socket_io_client_1 = require("socket.io-client");
// "undefined" means the URL will be computed from the `window.location` object
var URL = (_a = process.env.AUCTION_PLATFORM_API_URL) !== null && _a !== void 0 ? _a : "";
exports.socket = (0, socket_io_client_1.io)(URL, {
    transports: ["websocket"],
    // autoConnect: false,
});
