"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceBid = void 0;
var react_1 = __importStar(require("react"));
var bid_increments_1 = require("../../../../api/calls/client/bid_increments");
var parseJwt_1 = require("../../../../helpers/parseJwt");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var doshx_controls_web_1 = require("doshx_controls_web");
var place_bid_1 = require("../../../../api/calls/bid/place_bid");
var PlaceBid = function (props) {
    var _a, _b;
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.lotState; }), currentLot = _c.currentLot, highestBid = _c.highestBid;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _d = (0, react_1.useState)([]), increments = _d[0], setIncrements = _d[1];
    var _e = (0, react_1.useState)(null), selectedIncrement = _e[0], setSelectedIncrement = _e[1];
    (0, react_1.useEffect)(function () {
        (0, bid_increments_1.bid_increments)({
            client_entity_id: client_entity_id,
        }).then(function (response) {
            console.log(response);
            if (response.success) {
                if (response.content.bid_increments && response.content.bid_increments.length > 0) {
                    //sort asc
                    var sorted = response.content.bid_increments.sort(function (a, b) { return a - b; });
                    setIncrements(sorted);
                }
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Oops!",
                    messages: [
                        "Something went wrong while getting the bidding increments!",
                        response.message,
                    ],
                }));
            }
        });
    }, []);
    // const highestBid = highestBids[currentLot?.entity_id];
    var incrementAmount = (_b = ((highestBid === null || highestBid === void 0 ? void 0 : highestBid.amount) ? parseFloat(highestBid.amount) : 0) + selectedIncrement) !== null && _b !== void 0 ? _b : 0;
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 p-2" },
        react_1.default.createElement("div", { className: "font-bold text-xl text-center" }, "Place a Bid"),
        react_1.default.createElement("div", { className: "flex-1 flex flex-col items-center" },
            react_1.default.createElement("div", { className: "text-sm" }, "Current highest bid:"),
            react_1.default.createElement("div", { className: "text-md font-bold" }, doshx_controls_web_1.Utilities.formatMoney({
                value: ((highestBid === null || highestBid === void 0 ? void 0 : highestBid.amount) ? parseFloat(highestBid.amount) : 0).toString(),
                decimalPlaces: 2,
            }))),
        selectedIncrement ? (react_1.default.createElement("div", { className: "flex flex-col items-center" },
            react_1.default.createElement("div", { className: "text-sm" }, "You are about to place a bid for:"),
            react_1.default.createElement("div", { className: "text-md font-bold" }, doshx_controls_web_1.Utilities.formatMoney({
                value: incrementAmount.toString(),
                decimalPlaces: 2,
            })))) : (react_1.default.createElement("div", { className: "text-sm text-center" }, "Please select an increment amount below")),
        react_1.default.createElement("div", { className: "grid grid-cols-2 xl:grid-cols-4 gap-2" }, increments.map(function (increment, index) {
            return (react_1.default.createElement("div", { key: "increment-".concat(index), className: "" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "+ ".concat(doshx_controls_web_1.Utilities.formatMoney({ value: increment === null || increment === void 0 ? void 0 : increment.toString(), decimalPlaces: 0 })), backgroundColor: selectedIncrement == increment ? "bg-success" : "bg-primary", size: "small", onClick: function () {
                        setSelectedIncrement(increment);
                    } })));
        })),
        selectedIncrement && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Place Bid", onClick: function () {
                    //
                    (0, place_bid_1.place_bid)({
                        lot_entity_id: currentLot.entity_id,
                        user_entity_id: user === null || user === void 0 ? void 0 : user.entity_id,
                        amount: incrementAmount.toString(),
                    }).then(function (response) {
                        if (response.success) {
                            console.log("Bid placed successfully");
                            setSelectedIncrement(null);
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({
                                title: "Oops!",
                                messages: [
                                    "Something went wrong while placing your bid!",
                                    response.message,
                                ],
                            }));
                        }
                    });
                } })))));
};
exports.PlaceBid = PlaceBid;
