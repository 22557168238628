"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redux_store = void 0;
//DON’T USE THIS WAY OF ACCESSING THE STORE WITH SERVER-SIDE RENDERING!
//HOWEVER, IT"S FINE FOR US.
var toolkit_1 = require("@reduxjs/toolkit");
var reducers_1 = require("./reducers/reducers");
//Create our redux store, and connect to the devtools. :)
//The key here is to export the store.
var enable = process.env.ENABLE_REDUX_DEVTOOLS;
exports.redux_store = (0, toolkit_1.configureStore)({
    devTools: enable == "true",
    reducer: reducers_1.reducers,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            // This was here because we had onClick's in our objects.
            // Now we have the onClick's in our components - this warning would be valid.
            serializableCheck: false,
        });
    },
});
