"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sign_up_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var local_storage_names_1 = require("../../../constants/local_storage_names");
var main_1 = require("../main");
var user_1 = require("../user");
var sign_up_1 = require("../../../api/calls/user/sign_up");
var parseJwt_1 = require("../../../helpers/parseJwt");
var get_user_1 = require("../../../api/calls/user/get_user");
exports.sign_up_thunk = (0, toolkit_1.createAsyncThunk)("user/thunks/sign_up_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, user, client_entity_id, signUpResponse;
        var _b, _c, _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    state = getState();
                    user = state.userState.user;
                    client_entity_id = (_b = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _b === void 0 ? void 0 : _b.entity_id;
                    dispatch((0, user_1.setIsLoading)(true));
                    return [4 /*yield*/, (0, sign_up_1.sign_up)({
                            client_entity_id: client_entity_id,
                            name: (_c = user.name) !== null && _c !== void 0 ? _c : "",
                            surname: (_d = user.surname) !== null && _d !== void 0 ? _d : "",
                            email: (_e = user.email) !== null && _e !== void 0 ? _e : "",
                            cell_phone: user.cell_phone,
                            password: (_f = user.password) !== null && _f !== void 0 ? _f : "",
                            // birthday: user.birthday ?? new Date(),
                            get_communication: (_g = user.get_communication) !== null && _g !== void 0 ? _g : false,
                            agrees_terms_and_conditions: (_h = user.agrees_terms_and_conditions) !== null && _h !== void 0 ? _h : false,
                        })];
                case 1:
                    signUpResponse = _j.sent();
                    console.log("sign_up_thunk -> response", signUpResponse);
                    dispatch((0, user_1.setIsLoading)(false));
                    // const response = {
                    //   success: true,
                    //   content: {},
                    //   message: "Success",
                    // };
                    if (signUpResponse.success) {
                        (0, get_user_1.get_user)({
                            user_entity_id: signUpResponse.content.user.entity_id,
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            var usr;
                            return __generator(this, function (_a) {
                                dispatch((0, user_1.setIsLoading)(false));
                                if (response.success) {
                                    usr = {
                                        user: __assign(__assign({}, signUpResponse.content.user), response.content),
                                        token: signUpResponse.content.token,
                                    };
                                    dispatch((0, user_1.setUser)(usr.user));
                                    dispatch((0, user_1.setUserToken)(usr.token));
                                    if (user.rememberMe) {
                                        localStorage.setItem(local_storage_names_1.USER_LOCAL_STORAGE, JSON.stringify(usr));
                                    }
                                    dispatch((0, main_1.setDrawer)({
                                        open: false,
                                    }));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while retrieving user's details!", messages: [response.message] }));
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        // // const obj = {
                        // //   ...user,
                        // //   ...response.content,
                        // // };
                        // // console.log("sign_up_thunk -> obj", obj);
                        // if (user.rememberMe) {
                        //   // localStorage.set(USER_LOCAL_STORAGE, JSON.stringify(obj));
                        //   localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(response.content));
                        // }
                        // dispatch(setUserToken(response.content.token));
                        // // dispatch(setUser(obj));
                        // dispatch(setUser(response.content.user));
                        // dispatch(setDrawer({ open: false }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [signUpResponse.message] }));
                    }
                    return [2 /*return*/, signUpResponse];
            }
        });
    });
});
