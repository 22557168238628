"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsAuctionCompleted = exports.setLiveAuction = exports.setAuction = exports.setAuctions = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    auction: null,
    auctions: [],
    liveAuction: null,
    isAuctionCompleted: true,
    hasExtendedLots: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "auction",
    initialState: initialState,
    reducers: {
        setAuction: function (state, action) {
            var _a;
            state.auction = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setAuctions: function (state, action) {
            var _a;
            state.auctions = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setLiveAuction: function (state, action) {
            var _a;
            state.liveAuction = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setIsAuctionCompleted: function (state, action) {
            var _a;
            state.isAuctionCompleted = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setHasExtendedLots: function (state, action) {
            var _a;
            state.hasExtendedLots = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setAuctions = (_a = slice.actions, _a.setAuctions), exports.setAuction = _a.setAuction, exports.setLiveAuction = _a.setLiveAuction, exports.setIsAuctionCompleted = _a.setIsAuctionCompleted;
exports.default = slice.reducer;
