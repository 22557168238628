"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LivePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var socket_1 = require("../../../socket");
var main_1 = require("../../../reducers/slices/main");
var auction_1 = require("../../../reducers/slices/auction");
var no_live_auction_1 = require("./no_live_auction");
var manual_live_auction_1 = require("./manual/manual_live_auction");
var toast_1 = require("../../../helpers/toast");
var completed_1 = require("../../../components/auction/completed");
var container_1 = require("../../../controls/container");
var automated_live_auction_1 = require("./automated/automated_live_auction");
var countdown_1 = require("../../../reducers/slices/countdown");
var react_router_dom_1 = require("react-router-dom");
var get_auction_1 = require("../../../api/calls/auction/get_auction");
var LivePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var auctionID = (0, react_router_dom_1.useParams)().auctionID;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }), liveAuction = _a.liveAuction, isAuctionCompleted = _a.isAuctionCompleted;
    var _b = (0, react_1.useState)(false), isLoading = _b[0], setIsLoading = _b[1];
    (0, react_1.useEffect)(function () {
        if (auctionID) {
            (0, get_auction_1.get_auction)({
                entity_id: auctionID,
            }).then(function (response) {
                if (response.success) {
                    dispatch((0, auction_1.setLiveAuction)(response.content));
                    dispatch((0, auction_1.setIsAuctionCompleted)(false));
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    }, [auctionID]);
    (0, react_1.useEffect)(function () {
        if (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id) {
            socket_1.socket.emit("joinAuctionPipe", liveAuction.entity_id);
            socket_1.socket.on("connect", function () {
                console.log("connected/reconnected to socket");
                socket_1.socket.emit("joinAuctionPipe", liveAuction.entity_id);
            });
            socket_1.socket.off("lotExtended");
            socket_1.socket.on("lotExtended", function (data) {
                console.log("lotExtended SOCKET data:", data);
                var lot = data.lot;
                var entity_id = lot.entity_id, title = lot.title, lot_number = lot.lot_number;
                dispatch((0, countdown_1.setRandomRefresh)(doshx_controls_web_1.Utilities.randomAlphanumeric(10)));
                (0, toast_1.ourToast)({
                    content: "The bidding time for # ".concat(lot_number, " - ").concat(title, " has been extended!"),
                });
            });
            socket_1.socket.on("auctionStatus", function (data) {
                var entity_id = data.entity_id, status = data.status, has_extended_lots = data.has_extended_lots;
                if (entity_id == liveAuction.entity_id) {
                    console.log("auctionStatus", data);
                    dispatch((0, auction_1.setLiveAuction)(__assign(__assign({}, liveAuction), { status: status })));
                    if (status == "Complete") {
                        if (has_extended_lots) {
                            (0, toast_1.ourToast)({
                                content: (react_1.default.createElement("div", { className: "" },
                                    react_1.default.createElement("div", null, "The auction has completed!"),
                                    react_1.default.createElement("div", null, "However, some lots have been extended!"),
                                    react_1.default.createElement("div", null, "Hurry! Place your bids!"))),
                                onClick: function () {
                                    //
                                },
                            });
                        }
                        else {
                            dispatch((0, auction_1.setIsAuctionCompleted)(true));
                        }
                    }
                    else {
                    }
                }
            });
        }
    }, [liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id]);
    (0, react_1.useEffect)(function () {
        return function () {
            // socket.off("connect");
        };
    }, []);
    return (react_1.default.createElement("div", { className: "h-full" },
        react_1.default.createElement(container_1.ContainerControl, { className: "", loading: isLoading }, (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.status) == "In-Progress" ? (isAuctionCompleted ? (react_1.default.createElement(completed_1.CompletedComponent, null)) : liveAuction.automated.enabled ? (react_1.default.createElement(automated_live_auction_1.AutomatedLiveAuctionComponent, null)) : (react_1.default.createElement(manual_live_auction_1.ManualLiveAuctionComponent, null))) : (react_1.default.createElement(no_live_auction_1.NoLiveAuctionComponent, null)))));
};
exports.LivePage = LivePage;
