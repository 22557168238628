"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiddingHistoryComponent = void 0;
var react_1 = __importStar(require("react"));
var main_1 = require("../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var account_1 = require("./account");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var bidding_history_for_user_1 = require("../../../api/calls/bid/bidding_history_for_user");
var auction_status_tag_1 = require("../../../components/tags/auction_status_tag");
var BiddingHistoryComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)([]), biddingHistory = _a[0], setBiddingHistory = _a[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)(false), isLoading = _b[0], setIsLoading = _b[1];
    (0, react_1.useEffect)(function () {
        setIsLoading(true);
        (0, bidding_history_for_user_1.bidding_history_for_user)({
            user_entity_id: user.entity_id,
        }).then(function (response) {
            setIsLoading(false);
            if (response.success) {
                setBiddingHistory(response.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Error",
                    messages: [response.message],
                }));
            }
        });
    }, []);
    function determineStatus(status) {
        return (react_1.default.createElement("div", { className: "text-xs" }, (function () {
            switch (status) {
                case "Active":
                    return "";
                case "Rejected":
                    return react_1.default.createElement("div", { className: "bg-red-500 px-2 py-1 rounded-md text-white" }, "Rejected");
                default:
                    return status;
            }
        })()));
    }
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "pt-10 flex" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back to Account", size: "xsmall", startIcon: pro_solid_svg_icons_1.faArrowLeft, backgroundColor: "bg-secondary", onClick: function () {
                    dispatch((0, main_1.setDrawer)({
                        open: true,
                        content: react_1.default.createElement(account_1.AccountScreen, null),
                    }));
                } })),
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Bidding History", size: "large", bold: true, textColor: "text-black" })),
        isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : biddingHistory && biddingHistory.length > 0 ? (react_1.default.createElement("div", null,
            react_1.default.createElement(doshx_controls_web_1.AccordionControl, { addHeaderPadding: true, addContentPadding: false, arrowSide: "right", items: biddingHistory.map(function (auction, bidHistoryIdx) {
                    console.log("history", history);
                    return {
                        header: react_1.default.createElement("div", { className: "text-md font-bold" }, auction.title),
                        content: (react_1.default.createElement("div", { className: "pl-4 pb-4 flex flex-col gap-2" },
                            react_1.default.createElement("div", { className: "flex justify-between pr-4 items-center" },
                                react_1.default.createElement("div", { className: "text-xs" }, "These are the lots you have placed bids on."),
                                react_1.default.createElement(auction_status_tag_1.AuctionStatusTag, { auctionStatus: auction.status, size: "xsmall" })),
                            react_1.default.createElement(doshx_controls_web_1.AccordionControl, { addHeaderPadding: false, addContentPadding: false, arrowSide: "right", colors: {
                                    borderColor: "border-transparent",
                                }, items: auction.lots.map(function (lot, lotIdx) {
                                    var _a, _b, _c;
                                    var iWon = ((_a = lot.highest_bid) === null || _a === void 0 ? void 0 : _a.user_entity_id) == user.entity_id;
                                    return {
                                        header: (react_1.default.createElement("div", { className: "flex justify-between items-center w-full pr-1" },
                                            react_1.default.createElement("div", { className: "text-xs font-bold" }, lot.title),
                                            auction.status == "Complete" && (react_1.default.createElement("div", { className: "p-1 text-white text-xs break-keep rounded-md ".concat(iWon ? "bg-success" : "bg-error") }, iWon ? "You Won" : "You Lost")))),
                                        content: (react_1.default.createElement("div", { className: "" },
                                            react_1.default.createElement("div", { className: "flex justify-end pr-2" },
                                                react_1.default.createElement("div", { className: "flex gap-2 bg-blue-400 py-1 px-2 rounded-md text-sm text-white mb-4" },
                                                    react_1.default.createElement("span", null, "Highest Bid:"),
                                                    react_1.default.createElement("span", null, doshx_controls_web_1.Utilities.formatMoney({
                                                        value: (_c = (_b = lot.highest_bid) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : "0",
                                                        decimalPlaces: 2,
                                                    })))),
                                            react_1.default.createElement("div", { className: "" }, lot.bids.map(function (bid, bidIndex) {
                                                return (react_1.default.createElement("div", { className: "flex gap-4 items-center pl-4 pr-2 pb-4" },
                                                    react_1.default.createElement("div", { className: "flex-1" }, doshx_controls_web_1.Utilities.formatMoney({
                                                        value: bid.amount,
                                                        decimalPlaces: 2,
                                                    })),
                                                    react_1.default.createElement("div", { className: "text-xs" }, determineStatus(bid.status))));
                                            })))),
                                    };
                                }) }))),
                    };
                }) }))) : (react_1.default.createElement("div", null,
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You have not placed any bids yet.", textColor: "text-black" })))));
};
exports.BiddingHistoryComponent = BiddingHistoryComponent;
