"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLotSummary = exports.setSummary = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    summary: {},
    lotSummary: {},
};
var slice = (0, toolkit_1.createSlice)({
    name: "summary",
    initialState: initialState,
    reducers: {
        setSummary: function (state, action) {
            var _a;
            state.summary = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setLotSummary: function (state, action) {
            var _a;
            state.lotSummary = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setSummary = (_a = slice.actions, _a.setSummary), exports.setLotSummary = _a.setLotSummary;
exports.default = slice.reducer;
