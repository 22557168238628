"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBidderComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../reducers/slices/main");
var register_bidder_1 = require("../api/calls/bidder/register_bidder");
var bidder_1 = require("../reducers/slices/bidder");
var upload_file_1 = require("../api/calls/upload_file");
var parseJwt_1 = require("../helpers/parseJwt");
var RegisterBidderComponent = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var _b = (0, react_1.useState)(user.id_number), idNumber = _b[0], setIDNumber = _b[1];
    var _c = (0, react_1.useState)(""), idNumberMessage = _c[0], setIDNumberMessage = _c[1];
    var _d = (0, react_1.useState)(user.address), address = _d[0], setAddress = _d[1];
    var _e = (0, react_1.useState)(""), addressMessage = _e[0], setAddressMessage = _e[1];
    var _f = (0, react_1.useState)(), proofOfID = _f[0], setProofOfID = _f[1];
    var _g = (0, react_1.useState)(""), proofOfIDMessage = _g[0], setProofOfIDMessage = _g[1];
    var _h = (0, react_1.useState)(), proofOfResidence = _h[0], setProofOfResidence = _h[1];
    var _j = (0, react_1.useState)(""), proofOfResidenceMessage = _j[0], setProofOfResidenceMessage = _j[1];
    var _k = (0, react_1.useState)(0), idUploadProgress = _k[0], setIDUploadProgress = _k[1];
    var _l = (0, react_1.useState)(0), residenceUploadProgress = _l[0], setResidenceUploadProgress = _l[1];
    var _m = (0, react_1.useState)(false), isLoading = _m[0], setIsLoading = _m[1];
    var validate = function () {
        var messageCount = 0;
        setIDNumberMessage("");
        setAddressMessage("");
        setProofOfIDMessage("");
        setProofOfResidenceMessage("");
        if (!idNumber) {
            messageCount++;
            setIDNumberMessage("ID Number is required");
        }
        if (!address) {
            messageCount++;
            setAddressMessage("Address is required");
        }
        if (!proofOfID) {
            messageCount++;
            setProofOfIDMessage("Proof of ID is required");
        }
        if (!proofOfResidence) {
            messageCount++;
            setProofOfResidenceMessage("Proof of Residence is required");
        }
        return messageCount === 0 ? true : false;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 h-full justify-center" },
        react_1.default.createElement("div", { className: "flex flex-col gap-2" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Register to Bid", size: "2xlarge", bold: true, textColor: "text-black" }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "ID Number", value: idNumber, onChange: function (v) {
                        setIDNumber(v);
                    }, errorText: idNumberMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Address", value: address, onChange: function (v) {
                        setAddress(v);
                    }, errorText: addressMessage }),
                react_1.default.createElement("div", { className: "flex flex-col relative" },
                    react_1.default.createElement(doshx_controls_web_1.FileInputControl, { label: "Proof of ID", file: proofOfID, onChange: function (v) {
                            setProofOfID(v);
                        }, size: "medium", errorText: proofOfIDMessage }),
                    idUploadProgress < 100 && (react_1.default.createElement("div", { className: "h-2", style: {
                            width: "".concat(idUploadProgress, "%"),
                        } }))),
                react_1.default.createElement("div", { className: "flex flex-col relative" },
                    react_1.default.createElement(doshx_controls_web_1.FileInputControl, { label: "Proof of Residence", file: proofOfResidence, onChange: function (v) {
                            setProofOfResidence(v);
                        }, size: "medium", errorText: proofOfResidenceMessage }),
                    residenceUploadProgress < 100 && (react_1.default.createElement("div", { className: "h-2", style: {
                            width: "".concat(residenceUploadProgress, "%"),
                        } }))))),
        react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-evenly" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Register To Bid", loading: isLoading, onClick: function () {
                    if (validate()) {
                        setIsLoading(true);
                        (0, register_bidder_1.register_bidder)({
                            id_number: idNumber,
                            address: address,
                            bidder: {
                                client_entity_id: client_entity_id,
                                user_entity_id: user.entity_id,
                            },
                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        setIsLoading(false);
                                        if (!response.success) return [3 /*break*/, 3];
                                        setIsLoading(true);
                                        return [4 /*yield*/, (0, upload_file_1.upload_file)({
                                                bidder_entity_id: response.content.entity_id,
                                                type: "Document",
                                                custom_name: "proof_of_id",
                                                file: proofOfID,
                                                uploadProgress: function (percentCompleted) { return __awaiter(void 0, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        setIDUploadProgress(percentCompleted);
                                                        return [2 /*return*/];
                                                    });
                                                }); },
                                            }).then(function (response) {
                                                setIsLoading(false);
                                                console.log("response", response);
                                                if (response.success) {
                                                    //
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while uploading Proof of ID!", messages: [response.data.message] }));
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        setIsLoading(true);
                                        return [4 /*yield*/, (0, upload_file_1.upload_file)({
                                                bidder_entity_id: response.content.entity_id,
                                                type: "Document",
                                                custom_name: "proof_of_address",
                                                file: proofOfResidence,
                                                uploadProgress: function (percentCompleted) { return __awaiter(void 0, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        setIDUploadProgress(percentCompleted);
                                                        return [2 /*return*/];
                                                    });
                                                }); },
                                            }).then(function (response) {
                                                setIsLoading(false);
                                                console.log("response", response);
                                                if (response.success) {
                                                    //
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while uploading Proof of Address!", messages: [response.data.message] }));
                                                }
                                            })];
                                    case 2:
                                        _a.sent();
                                        dispatch((0, bidder_1.setIsBidderRegistered)(true));
                                        dispatch((0, main_1.setDrawer)({
                                            open: false,
                                        }));
                                        return [3 /*break*/, 4];
                                    case 3:
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while saving details!", messages: [response.message] }));
                                        _a.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                }, backgroundColor: "bg-primary" }))));
};
exports.RegisterBidderComponent = RegisterBidderComponent;
