"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = void 0;
var react_1 = __importStar(require("react"));
var Tabs = function (props) {
    var options = props.options;
    var _a = (0, react_1.useState)(options[0]), activeTab = _a[0], setActiveTab = _a[1];
    var _b = (0, react_1.useState)(options !== null && options !== void 0 ? options : []), tabs = _b[0], setTabs = _b[1];
    (0, react_1.useEffect)(function () {
        if ((options === null || options === void 0 ? void 0 : options.length) > 0) {
        }
    }, []);
    (0, react_1.useEffect)(function () {
        if (options) {
            setTabs(options);
            // set active tab to first tab if not set
            if (!activeTab) {
                console.log("setting active tab to first tab");
                setActiveTab(options[0]);
            }
        }
    }, [options]);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement("div", { className: "flex border-blue-500 border-x-4 border-t-4 rounded-t-xl overflow-clip border-b-4 h-10" }, tabs.map(function (tab, index) {
            var _a;
            return (react_1.default.createElement("div", { key: "tab-".concat(index), className: "flex-1 flex justify-center items-center\n              cursor-pointer text-xl font-bold transition-colors\n              ".concat((activeTab === null || activeTab === void 0 ? void 0 : activeTab.name) === tab.name
                    ? // active
                        "bg-blue-500 text-white"
                    : // inactive
                        "text-black hover:bg-gray-300", "\n            "), onClick: function () { return setActiveTab(tab); } }, ((_a = tab.name) !== null && _a !== void 0 ? _a : "").toUpperCase()));
        })),
        react_1.default.createElement("div", { className: "rounded-b-xl border-blue-500 border-x-4 border-b-4 p-4" }, activeTab === null || activeTab === void 0 ? void 0 : activeTab.content)));
};
exports.Tabs = Tabs;
