"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setHighestBid = exports.setCurrentLot = exports.setSelectedAutomatedLot = exports.setPreviousCurentNext = exports.setLots = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    lots: [],
    previousCurrentNext: null,
    selectedAutomatedLot: null,
    //NEW, check above usages and remove if necessary
    currentLot: null,
    highestBid: null,
};
var slice = (0, toolkit_1.createSlice)({
    name: "lot",
    initialState: initialState,
    reducers: {
        setLots: function (state, action) {
            var _a;
            state.lots = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setPreviousCurentNext: function (state, action) {
            var _a;
            state.previousCurrentNext = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setSelectedAutomatedLot: function (state, action) {
            var _a;
            state.selectedAutomatedLot = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setCurrentLot: function (state, action) {
            var _a;
            state.currentLot = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setHighestBid: function (state, action) {
            var _a;
            state.highestBid = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setLots = (_a = slice.actions, _a.setLots), exports.setPreviousCurentNext = _a.setPreviousCurentNext, exports.setSelectedAutomatedLot = _a.setSelectedAutomatedLot, exports.setCurrentLot = _a.setCurrentLot, exports.setHighestBid = _a.setHighestBid;
exports.default = slice.reducer;
