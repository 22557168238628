"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.upload_file = void 0;
var api_1 = require("../api");
var store_1 = require("../../store");
var upload_file = function (request) {
    var _a;
    var formData = new FormData();
    formData.append("type", request.type);
    formData.append("file", request.file);
    if (request.custom_name) {
        formData.append("custom_name", request.custom_name);
    }
    if (request.bidder_entity_id) {
        formData.append("bidder_entity_id", request.bidder_entity_id);
    }
    // const {
    //   user: { token },
    // } = redux_store.getState().userState;
    var userToken = store_1.redux_store.getState().userState.userToken;
    return new api_1.API().apiPost({
        baseUrl: (_a = process.env.AUCTION_PLATFORM_API_URL) !== null && _a !== void 0 ? _a : "",
        endpoint: "v3/file/upload",
        // content: request,
        // customHeaders: {
        //   client_token: process.env.CLIENT_TOKEN ?? "",
        // },
        // data: formData,
        data: formData,
        contentType: "multipart/form-data",
        uploadProgress: function (percentCompleted) {
            // console.log("v2/file/upload UPLOADING:", percentCompleted);
            if (request.uploadProgress) {
                request.uploadProgress(percentCompleted);
            }
        },
        authorization: userToken,
    });
};
exports.upload_file = upload_file;
