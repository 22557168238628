"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_2 = require("react-redux");
var user_1 = require("../reducers/slices/user");
var terms_and_conditions_1 = require("./terms_and_conditions");
var privacy_policy_1 = require("./privacy_policy");
var routes_1 = require("../constants/routes");
var local_storage_names_1 = require("../constants/local_storage_names");
var live_1 = require("./pages/live/live");
var main_1 = require("../reducers/slices/main");
var header_1 = require("../controls/header");
var footer_1 = require("../controls/footer");
require("react-toastify/dist/ReactToastify.css");
var react_toastify_1 = require("react-toastify");
var socket_1 = require("../socket");
var toast_1 = require("../helpers/toast");
var lot_details_1 = require("./pages/lot/lot_details");
var auctions_1 = require("./pages/auctions/auctions");
var contact_1 = require("./pages/contact");
var parseJwt_1 = require("../helpers/parseJwt");
var details_1 = require("./pages/auctions/details");
var auction_1 = require("../reducers/slices/auction");
var change_forgot_password_1 = require("./pages/login/change_forgot_password");
var details_2 = require("./pages/complete/details");
var lot_details_2 = require("./pages/complete/lot_details");
var MainScreen = function () {
    var _a, _b;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var socketConnected = (0, react_redux_2.useSelector)(function (state) { return state.mainState; }).socketConnected;
    var _c = (0, react_redux_2.useSelector)(function (state) { return state.mainState; }), messageModal = _c.messageModal, contentModal = _c.contentModal, drawer = _c.drawer;
    (0, react_1.useEffect)(function () {
        var userLocalStorage = localStorage.getItem(local_storage_names_1.USER_LOCAL_STORAGE);
        if (userLocalStorage) {
            var ls = JSON.parse(userLocalStorage);
            dispatch((0, user_1.setUserToken)(ls.token));
            dispatch((0, user_1.setUser)(ls.user));
        }
        else {
            //if im not on the reset_passwod page
            if (!location.pathname.includes(routes_1.RESET_PASSWORD_ROUTE)) {
                navigate(routes_1.AUCTIONS_ROUTE);
            }
        }
        console.log("Joining joinClientPipe", client_entity_id);
        socket_1.socket.emit("joinClientPipe", client_entity_id);
        socket_1.socket.on("connect", function () {
            socket_1.socket.emit("joinClientPipe", client_entity_id);
            dispatch((0, main_1.setSocketConnected)(true));
        });
        // socket.off("disconnect");
        socket_1.socket.on("disconnect", function () {
            dispatch((0, main_1.setSocketConnected)(false));
        });
        return function () {
            socket_1.socket.off("connect");
            socket_1.socket.off("disconnect");
        };
    }, []);
    // useeffect for locatin change
    (0, react_1.useEffect)(function () {
        socket_1.socket.off("auctionWentLive");
        socket_1.socket.on("auctionWentLive", function (data) {
            console.log("auctionWentLive SOCKET data:", data);
            dispatch((0, auction_1.setIsAuctionCompleted)(false));
            (0, toast_1.ourToast)({
                content: "Auction went live! ".concat(data.title),
                onClick: function () {
                    if (location.pathname.includes(routes_1.LIVE_ROUTE)) {
                        navigate(0); // refresh
                    }
                    else {
                        navigate(routes_1.LIVE_ROUTE);
                    }
                },
            });
        });
    }, [location.pathname]);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement("div", { className: "fixed right-2 bottom-2 h-10 w-10 hover:w-[180px] rounded-full \n          group transition-all duration-1000 flex justify-center items-center\n          overflow-hidden z-[1000] cursor-pointer\n          ".concat(socketConnected ? "bg-success" : "bg-error") },
            react_1.default.createElement("div", { className: "text-xs opacity-0 group-hover:opacity-100 transition-all duration-1000\n            whitespace-nowrap text-white font-bold" },
                "CONNECTION ",
                socketConnected ? "GOOD" : "LOST")),
        react_1.default.createElement("div", { className: "absolute top-0 left-0 h-full w-full flex flex-col" },
            react_1.default.createElement(header_1.HeaderControl, null),
            react_1.default.createElement("div", { className: "z-0 flex-1 p-10 lg:p-14 relative" },
                react_1.default.createElement(react_router_dom_1.Routes, null,
                    react_1.default.createElement(react_router_dom_1.Route, { index: true, element: react_1.default.createElement(react_router_dom_1.Navigate, { to: routes_1.AUCTIONS_ROUTE }) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.LIVE_ROUTE, "/*"), element: react_1.default.createElement(live_1.LivePage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.AUCTIONS_ROUTE, "/*"), element: react_1.default.createElement(auctions_1.AuctionsPage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.AUCTION_DETAILS_ROUTE, "/:auctionID?"), element: react_1.default.createElement(details_1.AuctionDetailsPage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.LOT_DETAILS_ROUTE, "/:lot_id?/:auction_status?"), element: react_1.default.createElement(lot_details_1.LotDetailsPage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.LOT_COMPLETE_DETAILS_ROUTE, "/:lot_id??"), element: react_1.default.createElement(lot_details_2.LotCompleteDetailsPage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.AUCTION_LIVE_ROUTE, "/:auctionID?"), element: react_1.default.createElement(live_1.LivePage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.AUCTION_COMPLETE_ROUTE, "/:auctionID?"), element: react_1.default.createElement(details_2.AuctionCompletePage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.CONTACT_ROUTE, "/*"), element: react_1.default.createElement(contact_1.ContactPage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.RESET_PASSWORD_ROUTE, "/:reset_token?"), element: react_1.default.createElement(change_forgot_password_1.ChangeForgotPasswordComponent, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.TERMS_AND_CONDITIONS_ROUTE, "/*"), element: react_1.default.createElement(terms_and_conditions_1.TermsAndConditionsPage, null) }),
                    react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.PRIVACY_POLICY_ROUTE, "/*"), element: react_1.default.createElement(privacy_policy_1.PrivacyPolicyPage, null) }))),
            react_1.default.createElement(footer_1.FooterControl, null)),
        react_1.default.createElement(react_toastify_1.ToastContainer, null),
        react_1.default.createElement(doshx_controls_web_1.DrawerControl, { open: drawer.open, close: {
                backgroundColor: "bg-primary",
            }, toggleOpen: function (open) {
                dispatch((0, main_1.setDrawer)({
                    open: open,
                }));
            } },
            react_1.default.createElement("div", { className: "w-96 grid overflow-y-auto" }, drawer.content)),
        messageModal && (react_1.default.createElement(doshx_controls_web_1.ModalControl, { title: messageModal.title, open: true, onClose: function () {
                dispatch((0, main_1.setMessageModal)(null));
            } },
            react_1.default.createElement("div", { className: "p-4" }, messageModal.messages.map(function (message, index) {
                return react_1.default.createElement("div", { key: "modal-message-".concat(index) }, message);
            })))),
        contentModal && (react_1.default.createElement(doshx_controls_web_1.ModalControl, { title: contentModal.title, open: true, backgroundColor: (_b = contentModal.backgroundColor) !== null && _b !== void 0 ? _b : "bg-white", closeOnBackdrop: contentModal.backdropClose, onClose: function () {
                dispatch((0, main_1.setContentModal)(null));
            } },
            react_1.default.createElement("div", { className: "" }, contentModal.content)))));
};
exports.MainScreen = MainScreen;
