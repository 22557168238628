"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDetails = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var user_1 = require("../../../reducers/slices/user");
var change_password_thunk_1 = require("../../../reducers/slices/thunks/change_password_thunk");
var update_user_details_1 = require("../../../api/calls/user/update_user_details");
var main_1 = require("../../../reducers/slices/main");
var local_storage_names_1 = require("../../../constants/local_storage_names");
var UserDetails = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _a.user, isLoading = _a.isLoading;
    // const [cc, setCC] = useState<any>("ZA");
    var _b = (0, react_1.useState)(user === null || user === void 0 ? void 0 : user.name), name = _b[0], setName = _b[1];
    var _c = (0, react_1.useState)(user === null || user === void 0 ? void 0 : user.surname), surname = _c[0], setSurname = _c[1];
    var _d = (0, react_1.useState)(user === null || user === void 0 ? void 0 : user.email), email = _d[0], setEmail = _d[1];
    // const [oldPassword, setOldPassword] = useState<string>("");
    var _e = (0, react_1.useState)(""), newPassword = _e[0], setNewPassword = _e[1];
    var _f = (0, react_1.useState)(""), confirmPassword = _f[0], setConfirmPassword = _f[1];
    var _g = (0, react_1.useState)(user === null || user === void 0 ? void 0 : user.cell_phone.number), cellNumber = _g[0], setCellNumber = _g[1];
    var _h = (0, react_1.useState)(user === null || user === void 0 ? void 0 : user.cell_phone.country_code), cellCountryCode = _h[0], setCellCountryCode = _h[1];
    var _j = (0, react_1.useState)(user === null || user === void 0 ? void 0 : user.cell_phone.calling_code), cellCallingCode = _j[0], setCellCallingCode = _j[1];
    var _k = (0, react_1.useState)(""), nameMessage = _k[0], setNameMessage = _k[1];
    var _l = (0, react_1.useState)(""), surnameMessage = _l[0], setSurnameMessage = _l[1];
    var _m = (0, react_1.useState)(""), emailMessage = _m[0], setEmailMessage = _m[1];
    var _o = (0, react_1.useState)(""), newPasswordMessage = _o[0], setNewPasswordMessage = _o[1];
    var _p = (0, react_1.useState)(""), confirmPasswordMessage = _p[0], setConfirmPasswordMessage = _p[1];
    var _q = (0, react_1.useState)(""), cellNumberMessage = _q[0], setCellNumberMessage = _q[1];
    var _r = (0, react_1.useState)(false), isLoadingPasswordChange = _r[0], setIsLoadingPasswordChange = _r[1];
    var _s = (0, react_1.useState)(false), isLoadingDetailsChange = _s[0], setIsLoadingDetailsChange = _s[1];
    var _t = (0, react_1.useState)(false), isChangingPassword = _t[0], setIsChangingPassword = _t[1];
    var validate = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setCellNumberMessage("");
        if (!name) {
            messageCount++;
            setNameMessage("Name is required");
        }
        if (!surname) {
            messageCount++;
            setSurnameMessage("Surname is required");
        }
        if (!email) {
            messageCount++;
            setEmailMessage("Email is required");
        }
        else {
            if (!email.includes("@") || !email.includes(".")) {
                messageCount++;
                setEmailMessage("Email is invalid");
            }
        }
        if (!cellNumber) {
            messageCount++;
            setCellNumberMessage("Cell Number is required");
        }
        else {
            console.log(cellNumber, cellCountryCode);
            var isValid = doshx_controls_web_1.Utilities.isValidPhoneNumber(cellNumber, cellCountryCode);
            if (!isValid) {
                messageCount++;
                setCellNumberMessage("Cell Number is invalid. Please check your number and country.");
            }
        }
        return messageCount === 0 ? true : false;
    };
    var validatePasswords = function () {
        var messageCount = 0;
        // setSetOldPasswordMessage("");
        setNewPasswordMessage("");
        setConfirmPasswordMessage("");
        if (!newPassword) {
            messageCount++;
            setNewPasswordMessage("New Password is required");
        }
        if (!confirmPassword) {
            messageCount++;
            setConfirmPasswordMessage("Confirm Password is required");
        }
        else {
            if (newPassword !== confirmPassword) {
                messageCount++;
                setConfirmPasswordMessage("The Passwords do not match");
            }
        }
        return messageCount === 0 ? true : false;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "User Details", size: "xlarge", bold: true, textColor: "text-black" }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: name, onChange: function (v) {
                        setName(v);
                    }, errorText: nameMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: surname, onChange: function (v) {
                        setSurname(v);
                    }, errorText: surnameMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: email, disabled: true, onChange: function (v) {
                        setEmail(v);
                    }, errorText: emailMessage }),
                react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Phone Number", value: cellNumber, defaultCountryCode: cellCountryCode, size: "medium", onChange: function (v) {
                        setCellNumber(v);
                    }, onExtensionChange: function (v, cc) {
                        setCellCallingCode(v);
                        setCellCountryCode(cc);
                    }, errorText: cellNumberMessage }))),
        react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Update", loading: isLoadingDetailsChange, size: "small", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (validate()) {
                            // await dispatch(
                            //   update_user_details_thunk({
                            //     name,
                            //     surname,
                            //     email,
                            //     cell_number: cellNumber,
                            //     cell_country_code: cellCountryCode,
                            //   }),
                            // );
                            setIsLoadingDetailsChange(true);
                            (0, update_user_details_1.update_user_details)({
                                entity_id: user.entity_id,
                                entity: {
                                    name: name,
                                    surname: surname,
                                    email: email,
                                    cell_phone: {
                                        calling_code: cellCallingCode,
                                        country_code: cellCountryCode,
                                        number: cellNumber,
                                    },
                                    get_communication: user.get_communication,
                                    agrees_terms_and_conditions: user.agrees_terms_and_conditions,
                                },
                            }).then(function (response) {
                                setIsLoadingDetailsChange(false);
                                if (response.success) {
                                    dispatch((0, user_1.setUser)(response.content.user));
                                    dispatch((0, user_1.setUserToken)(response.content.token));
                                    if (localStorage.getItem(local_storage_names_1.USER_LOCAL_STORAGE)) {
                                        localStorage.setItem(local_storage_names_1.USER_LOCAL_STORAGE, JSON.stringify(response.content));
                                    }
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Successfully updated!",
                                        messages: ["You have successfully updated your details!"],
                                    }));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Oops!",
                                        messages: [
                                            "Something went wrong while we were updating your details.",
                                            response.message,
                                        ],
                                    }));
                                }
                            });
                        }
                        return [2 /*return*/];
                    });
                }); }, backgroundColor: "bg-success" }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Change Password", size: "small", onClick: function () {
                    setIsChangingPassword(true);
                }, backgroundColor: "bg-warning" })),
        isChangingPassword && (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Change Password", size: "xlarge", bold: true, textColor: "text-black" }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "New Password", type: "password", value: newPassword, onChange: function (v) {
                    setNewPassword(v);
                }, errorText: newPasswordMessage }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Confirm Password", type: "password", value: confirmPassword, onChange: function (v) {
                    setConfirmPassword(v);
                }, errorText: confirmPasswordMessage }),
            react_1.default.createElement("div", { className: "flex gap-4 justify-center" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Update Password", loading: isLoadingPasswordChange, backgroundColor: "bg-success", size: "small", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!validatePasswords()) return [3 /*break*/, 2];
                                    setIsLoadingPasswordChange(true);
                                    return [4 /*yield*/, dispatch((0, change_password_thunk_1.change_password_thunk)({
                                            new_password: newPassword,
                                        }))];
                                case 1:
                                    response = _a.sent();
                                    setIsLoadingPasswordChange(false);
                                    if (response.payload.success) {
                                        setIsChangingPassword(false);
                                    }
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); } }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", loading: isLoading, backgroundColor: "bg-error", size: "small", onClick: function () {
                        setIsChangingPassword(false);
                    } }))))));
};
exports.UserDetails = UserDetails;
