"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSocketConnected = exports.setDrawer = exports.setContentModal = exports.setMessageModal = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    messageModal: null,
    contentModal: null,
    drawer: {
        open: false,
        type: null,
        content: null,
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "main",
    initialState: initialState,
    reducers: {
        setMessageModal: function (state, action) {
            var _a;
            state.messageModal = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setContentModal: function (state, action) {
            var _a;
            state.contentModal = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setDrawer: function (state, action) {
            var _a;
            state.drawer = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setSocketConnected: function (state, action) {
            var _a;
            state.socketConnected = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setMessageModal = (_a = slice.actions, _a.setMessageModal), exports.setContentModal = _a.setContentModal, exports.setDrawer = _a.setDrawer, exports.setSocketConnected = _a.setSocketConnected;
exports.default = slice.reducer;
