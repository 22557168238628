"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidderStatusComponent = void 0;
var react_1 = __importStar(require("react"));
var bidder_status_1 = require("../api/calls/bidder/bidder_status");
var bidder_1 = require("../reducers/slices/bidder");
var react_redux_1 = require("react-redux");
var main_1 = require("../reducers/slices/main");
var parseJwt_1 = require("../helpers/parseJwt");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../constants/routes");
var register_bidder_1 = require("./register_bidder");
var login_1 = require("../screens/pages/login/login");
var socket_1 = require("../socket");
var BidderStatusComponent = function () {
    var _a;
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.bidderState; }), isBidderVerified = _b.isBidderVerified, isBidderRegistered = _b.isBidderRegistered, bidder = _b.bidder;
    (0, react_1.useEffect)(function () {
        if ((user === null || user === void 0 ? void 0 : user.entity_id) && client_entity_id) {
            getBidderVerificationStatus();
            socket_1.socket.emit("joinUserPipe", user.entity_id);
            socket_1.socket.on("verifyBidder", function (data) {
                console.log("verifyBidder", data);
                getBidderVerificationStatus();
            });
        }
    }, [user]);
    function getBidderVerificationStatus() {
        (0, bidder_status_1.bidder_status)({
            client_entity_id: client_entity_id,
            user_entity_id: user.entity_id,
        }).then(function (response) {
            if (response.success) {
                dispatch((0, bidder_1.setIsBidderRegistered)(response.content.is_registered));
                dispatch((0, bidder_1.setIsBidderVerified)(response.content.is_verified));
                dispatch((0, bidder_1.setBidder)(response.content.bidder));
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Oops!",
                    messages: [
                        "Something went wrong while getting your verification status.",
                        response.message,
                    ],
                }));
            }
        });
    }
    return (react_1.default.createElement("div", { className: "" }, user && user.entity_id ? (isBidderRegistered ? (isBidderVerified ? (react_1.default.createElement("div", { className: "bg-green-600 py-1 px-4 rounded-full shadow-sm \n              text-white text-center" },
        "Registered & verified. Your paddle number is ",
        react_1.default.createElement("span", { className: "font-bold" },
            "# ", bidder === null || bidder === void 0 ? void 0 :
            bidder.paddle_number,
            "."))) : (react_1.default.createElement("div", { className: "bg-orange-400 py-1 px-4 rounded-full shadow-sm \n                text-white text-center animate-scale" },
        "You are not verified to bid yet. Feel free to remind us by going to",
        " ",
        react_1.default.createElement("span", { className: "font-bold hover:underline cursor-pointer", onClick: function () {
                navigate(routes_1.CONTACT_ROUTE);
            } }, "Contact Us."),
        " ",
        "You may browse the lots while you wait!"))) : (react_1.default.createElement("div", { className: "bg-red-500 py-1 px-4 rounded-full shadow-sm \n                text-white text-center animate-scale cursor-pointer hover:underline", onClick: function () {
            dispatch((0, main_1.setDrawer)({
                open: true,
                content: react_1.default.createElement(register_bidder_1.RegisterBidderComponent, null),
            }));
        } },
        "You haven't registered to bid yet! Click ",
        react_1.default.createElement("b", null, "here"),
        " to register!"))) : (react_1.default.createElement("div", { className: "bg-primary py-1 px-4 rounded-full shadow-sm \n              text-white text-center animate-scale cursor-pointer hover:underline", onClick: function () {
            dispatch((0, main_1.setDrawer)({
                open: true,
                content: (react_1.default.createElement("div", { className: "w-96" },
                    react_1.default.createElement(login_1.LogInComponent, null))),
            }));
        } },
        "You need to be logged in to bid. Click ",
        react_1.default.createElement("b", null, "here"),
        " to log in."))));
};
exports.BidderStatusComponent = BidderStatusComponent;
