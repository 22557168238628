"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentBidding = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var lot_1 = require("../../../../reducers/slices/lot");
var bids_for_lot_1 = require("../../../../api/calls/bid/bids_for_lot");
var main_1 = require("../../../../reducers/slices/main");
var doshx_controls_web_1 = require("doshx_controls_web");
var place_bid_1 = require("./place_bid");
var socket_1 = require("../../../../socket");
var get_lot_1 = require("../../../../api/calls/lot/get_lot");
var lot_details_1 = require("../modals/lot_details");
var you_tag_1 = require("../../../../components/tags/you_tag");
var toast_1 = require("../../../../helpers/toast");
var lot_status_tag_1 = require("../../../../components/tags/lot_status_tag");
var login_1 = require("../../login/login");
var floor_1 = require("../../../../constants/floor");
var CurrentBidding = function () {
    var _a, _b, _c, _d;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var currentLot = (0, react_redux_1.useSelector)(function (state) { return state.lotState; }).currentLot;
    var _e = (0, react_1.useState)(null), lotDetails = _e[0], setLotDetails = _e[1];
    var _f = (0, react_1.useState)({
        count: 0,
        bids: [],
    }), bids = _f[0], setBids = _f[1];
    (0, react_1.useEffect)(function () {
        socket_1.socket.off("newBid");
        socket_1.socket.on("newBid", function (data) {
            var bid = data.bid, count = data.count;
            var lot = bid.lot;
            if (lot.entity_id === currentLot.entity_id) {
                dispatch((0, lot_1.setHighestBid)(bid));
                bids.bids.unshift(bid);
                // remove the last bid if the length is more than 9.
                if (bids.bids.length > 9) {
                    bids.bids.pop();
                }
                setBids(function (prev) {
                    return prev;
                });
            }
        });
        socket_1.socket.off("rejectBid");
        socket_1.socket.on("rejectBid", function (data) {
            var bid_entity_id = data.bid_entity_id, lot_entity_id = data.lot_entity_id;
            var bid = bids.bids.find(function (x) { return x.entity_id === bid_entity_id; });
            if (bid.user.entity_id === user.entity_id) {
                (0, toast_1.ourToast)({
                    content: "Your bid was rejected.",
                });
            }
            bids.bids = bids.bids.filter(function (x) { return x.entity_id !== bid_entity_id; });
            setBids(function (prev) { return (__assign({}, prev)); });
        });
        socket_1.socket.off("backUpBids");
        socket_1.socket.on("backUpBids", function (data) {
            var bid_entity_ids = data.bid_entity_ids, lot_entity_id = data.lot_entity_id;
            var bs = bids.bids.filter(function (x) { return bid_entity_ids.includes(x.entity_id); });
            for (var _i = 0, bs_1 = bs; _i < bs_1.length; _i++) {
                var b = bs_1[_i];
                if (b.user.entity_id === user.entity_id) {
                    (0, toast_1.ourToast)({
                        content: "One of your bids were rejected.",
                    });
                }
            }
            bids.bids = bids.bids.filter(function (x) { return !bid_entity_ids.includes(x.entity_id); });
            setBids(function (prev) { return (__assign({}, prev)); });
        });
    }, [bids.bids]);
    (0, react_1.useEffect)(function () {
        if (currentLot) {
            socket_1.socket.on("lotStatusForAuction", function (data) {
                var lot_entity_id = data.lot_entity_id, auction_entity_id = data.auction_entity_id, title = data.title, status = data.status, type = data.type, highest_bid = data.highest_bid;
                if (currentLot.entity_id === lot_entity_id) {
                    determineWonLost(data);
                    //set current lot status to reflect on ui
                    dispatch((0, lot_1.setCurrentLot)(__assign(__assign({}, currentLot), { status: status })));
                }
            });
            (0, get_lot_1.get_lot)({
                entity_id: currentLot.entity_id,
            }).then(function (response) {
                if (response.success) {
                    setLotDetails(response.content);
                    // setSelectedDisplayImage(response.content.images[0]);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while retrieving lot details!", messages: [response.message] }));
                }
            });
            (0, bids_for_lot_1.bids_for_lot)({
                entity_id: currentLot.entity_id,
            }).then(function (response) {
                if (response.success) {
                    setBids(response.content);
                    dispatch((0, lot_1.setHighestBid)(response.content.bids[0]));
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Oops!",
                        messages: ["Something went wrong while we were getting the bids for this lot.", response.message],
                    }));
                }
            });
        }
        return function () {
            socket_1.socket.off("lotStatusForAuction");
        };
    }, [currentLot]);
    var determineWonLost = function (data) {
        var lot_entity_id = data.lot_entity_id, auction_entity_id = data.auction_entity_id, title = data.title, status = data.status, type = data.type, highest_bid = data.highest_bid;
        console.log("determineWonLost", data);
        if (status == "Sold") {
            if ((highest_bid === null || highest_bid === void 0 ? void 0 : highest_bid.user_entity_id) == (user === null || user === void 0 ? void 0 : user.entity_id)) {
                (0, toast_1.ourToast)({
                    content: "You won lot ".concat(title, "!"),
                });
            }
            else {
                // TODO: don't show this toast if the user never palced a bid on this lot.
                (0, toast_1.ourToast)({
                    content: "You lost lot ".concat(title, "!"),
                });
            }
        }
        else if (status == "Passed") {
            (0, toast_1.ourToast)({
                content: "The lot was passed.",
            });
        }
    };
    console.log("user.entity_id", user.entity_id);
    return currentLot ? (react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4 order-2 lg:order-1" },
            react_1.default.createElement("div", { className: "flex gap-4 items-center bg-gray-200 p-4 rounded-lg shadow-md" },
                react_1.default.createElement("div", { className: "font-bold text-xl" }, "Highest Bid:"),
                react_1.default.createElement("div", { className: "font-bold text-2xl" }, doshx_controls_web_1.Utilities.formatMoney({ value: ((_b = (_a = bids.bids[0]) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0).toString(), decimalPlaces: 2 })),
                ((_c = bids.bids[0]) === null || _c === void 0 ? void 0 : _c.user.entity_id) === (user === null || user === void 0 ? void 0 : user.entity_id) && react_1.default.createElement(you_tag_1.YouTag, null)),
            react_1.default.createElement("div", { className: "flex flex-col bg-gray-200 p-4 rounded-lg shadow-md" },
                ((_d = bids.bids) === null || _d === void 0 ? void 0 : _d.length) > 0 ? (bids.bids.map(function (bid, index) {
                    return (react_1.default.createElement("div", { key: "bid-".concat(index), className: "flex gap-2 p-2 rounded-md ".concat(index % 2 == 0 ? "bg-white" : "") },
                        react_1.default.createElement("div", { className: "font-bold text-lg" }, doshx_controls_web_1.Utilities.formatMoney({ value: bid.amount.toString(), decimalPlaces: 2 })),
                        react_1.default.createElement(react_1.default.Fragment, null, "-"),
                        bid.user.entity_id == floor_1.FLOOR_ENTITY_ID ? (react_1.default.createElement("div", { className: "font-bold text-md" }, "Floor Bid")) : (react_1.default.createElement("div", { className: "font-bold text-md" },
                            "#",
                            bid.bidder.paddle_number)),
                        bid.user.entity_id === user.entity_id && react_1.default.createElement(you_tag_1.YouTag, null)));
                })) : (react_1.default.createElement("div", { className: "font-bold text-sm" }, "No bids yet. Be the first! Place a bid!")),
                bids.count > 10 && react_1.default.createElement("div", { className: "font-bold text-sm" }, "Older bids are not shown."))),
        react_1.default.createElement("div", { className: "flex flex-col gap-4 order-1 lg:order-2" },
            lotDetails && (react_1.default.createElement("div", { className: "flex flex-row items-center gap-4 bg-gray-200 p-4 rounded-lg shadow-md" },
                lotDetails.images && lotDetails.images.length > 0 && (react_1.default.createElement("div", { className: "w-20 h-20 rounded-md overflow-clip shadow-md" },
                    react_1.default.createElement("img", { className: "w-full h-full object-cover", src: lotDetails.images[0].uploaded_file_url }),
                    " ")),
                react_1.default.createElement("div", { className: "flex-1 flex items-center flex-col gap-4 md:flex-row" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex flex-col gap-1 items-center" },
                            react_1.default.createElement("div", { className: "font-bold text-lg" },
                                "# ",
                                lotDetails.lot_number),
                            react_1.default.createElement("div", { className: "text-md font-bold" }, lotDetails.title))),
                    react_1.default.createElement("div", { className: "flex-1 flex flex-col items-center" },
                        react_1.default.createElement("div", { className: "line-clamp-4" }, lotDetails.description))),
                react_1.default.createElement("div", { className: "flex flex-col gap-2 justify-center" },
                    react_1.default.createElement("div", { className: "text-white text-xs bg-blue-500 px-2 py-2 rounded-lg cursor-pointer hover:bg-blue-400\n                  flex items-center justify-center", onClick: function () {
                            dispatch((0, main_1.setContentModal)({
                                title: "Viewing Lot Details",
                                content: react_1.default.createElement(lot_details_1.LotDetailsModal, { lot_id: lotDetails.entity_id }),
                            }));
                        } }, "View Details"),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(lot_status_tag_1.LotStatusTag, { auctionStatus: null, lotStatus: lotDetails.status, size: "xsmall" }))))),
            react_1.default.createElement("div", { className: "flex-1 lg:flex lg:justify-start" },
                react_1.default.createElement("div", { className: "flex" }, (user === null || user === void 0 ? void 0 : user.entity_id) ? (react_1.default.createElement("div", { className: "bg-gray-200 p-4 rounded-lg shadow-md flex items-center justify-center" },
                    react_1.default.createElement(place_bid_1.PlaceBid, { lot_id: currentLot.entity_id }))) : (react_1.default.createElement("div", { className: "text-center bg-red-200 p-4 rounded-lg flex items-center justify-center cursor-pointer", onClick: function () {
                        dispatch((0, main_1.setDrawer)({
                            open: true,
                            content: react_1.default.createElement(login_1.LogInComponent, null),
                        }));
                    } }, "Please log-in to place a bid"))))))) : (react_1.default.createElement(react_1.default.Fragment, null));
};
exports.CurrentBidding = CurrentBidding;
