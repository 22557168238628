"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var user_1 = require("../../reducers/slices/user");
var sign_up_thunk_1 = require("../../reducers/slices/thunks/sign_up_thunk");
var main_1 = require("../../reducers/slices/main");
var login_1 = require("./login/login");
var SignUpComponent = function () {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _c.user, isLoading = _c.isLoading;
    var _d = (0, react_1.useState)("ZA"), cc = _d[0], setCC = _d[1];
    var _e = (0, react_1.useState)(""), ext = _e[0], setExt = _e[1];
    var _f = (0, react_1.useState)(""), nameMessage = _f[0], setNameMessage = _f[1];
    var _g = (0, react_1.useState)(""), surnameMessage = _g[0], setSurnameMessage = _g[1];
    var _h = (0, react_1.useState)(""), emailMessage = _h[0], setEmailMessage = _h[1];
    var _j = (0, react_1.useState)(""), passwordMessage = _j[0], setPasswordMessage = _j[1];
    var _k = (0, react_1.useState)(""), confirmPasswordMessage = _k[0], setConfirmPasswordMessage = _k[1];
    var _l = (0, react_1.useState)(""), agreesTermsMessage = _l[0], setAgreesTermsMessage = _l[1];
    var _m = (0, react_1.useState)(""), getCommunicationMessage = _m[0], setGetCommunicationMessage = _m[1];
    var _o = (0, react_1.useState)(""), cellNumberMessage = _o[0], setCellNumberMessage = _o[1];
    var onControlChange = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var _a;
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPasswordMessage("");
        setConfirmPasswordMessage("");
        setAgreesTermsMessage("");
        setGetCommunicationMessage("");
        setCellNumberMessage("");
        if (!user.name) {
            messageCount++;
            setNameMessage("Name is required");
        }
        if (!user.surname) {
            messageCount++;
            setSurnameMessage("Surname is required");
        }
        if (!user.email) {
            messageCount++;
            setEmailMessage("Email is required");
        }
        else {
            if (!user.email.includes("@") || !user.email.includes(".")) {
                messageCount++;
                setEmailMessage("Email is invalid");
            }
        }
        if (!user.password) {
            messageCount++;
            setPasswordMessage("Password is required");
        }
        if (!user.confirmPassword) {
            messageCount++;
            setConfirmPasswordMessage("Confirm Password is required");
        }
        else {
            if (user.password !== user.confirmPassword) {
                messageCount++;
                setConfirmPasswordMessage("Passwords do not match");
            }
        }
        if (!user.agrees_terms_and_conditions) {
            messageCount++;
            setAgreesTermsMessage("Agrees Terms is required");
        }
        if (!((_a = user.cell_phone) === null || _a === void 0 ? void 0 : _a.number)) {
            messageCount++;
            setCellNumberMessage("Cell Number is required");
        }
        else {
            console.log("user.cell_number", user.cell_phone.number);
            var isValid = doshx_controls_web_1.Utilities.isValidPhoneNumber(user.cell_phone.number, cc);
            console.log("isValid", isValid);
            if (!isValid) {
                messageCount++;
                setCellNumberMessage("Cell Number is invalid");
            }
        }
        return messageCount === 0 ? true : false;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 h-full justify-center" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Sign Up", size: "2xlarge", bold: true, textColor: "text-black" }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: user.name, onChange: function (v) {
                        onControlChange("name", v);
                    }, errorText: nameMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: user.surname, onChange: function (v) {
                        onControlChange("surname", v);
                    }, errorText: surnameMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: user.email, onChange: function (v) {
                        onControlChange("email", v);
                    }, errorText: emailMessage }),
                react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Phone Number", value: (_b = (_a = user.cell_phone) === null || _a === void 0 ? void 0 : _a.number) !== null && _b !== void 0 ? _b : "", defaultCountryCode: cc, size: "medium", onChange: function (v) {
                        console.log("PhoneInputControl onChange 1", v);
                        dispatch((0, user_1.setUser)(__assign(__assign({}, user), { cell_phone: __assign(__assign({}, user.cell_phone), { number: v }) })));
                    }, onExtensionChange: function (ext, cc) {
                        setCC(cc);
                        setExt(ext);
                        dispatch((0, user_1.setUser)(__assign(__assign({}, user), { cell_phone: __assign(__assign({}, user.cell_phone), { country_code: cc, calling_code: ext }) })));
                    }, errorText: cellNumberMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", type: "password", value: user.password, onChange: function (v) {
                        onControlChange("password", v);
                    }, errorText: passwordMessage }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Confirm Password", type: "password", value: user.confirmPassword, onChange: function (v) {
                        onControlChange("confirmPassword", v);
                    }, errorText: confirmPasswordMessage })),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "You agree to our terms and conditions?", value: user.agrees_terms_and_conditions, onChange: function (v) {
                    onControlChange("agrees_terms_and_conditions", v);
                }, errorText: agreesTermsMessage }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Get communication from us?", value: user.get_communication, onChange: function (v) {
                    onControlChange("get_communication", v);
                }, errorText: getCommunicationMessage }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Remember me?", value: user.rememberMe, onChange: function (v) {
                    onControlChange("rememberMe", v);
                } })),
        react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Sign Up", loading: isLoading, size: "small", backgroundColor: "bg-success", onClick: function () {
                    if (validate()) {
                        dispatch((0, sign_up_thunk_1.sign_up_thunk)());
                    }
                } }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back to Log In", size: "small", backgroundColor: "bg-primary", onClick: function () {
                    dispatch((0, main_1.setDrawer)({
                        open: true,
                        content: react_1.default.createElement(login_1.LogInComponent, null),
                    }));
                } }))));
};
exports.SignUpComponent = SignUpComponent;
