"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setBidder = exports.setIsBidderRegistered = exports.setIsBidderVerified = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    isBidderVerified: false,
    isBidderRegistered: false,
    bidder: null,
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "bidder",
    initialState: initialState,
    reducers: {
        setIsBidderVerified: function (state, action) {
            var _a;
            state.isBidderVerified = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setIsBidderRegistered: function (state, action) {
            var _a;
            state.isBidderRegistered = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setBidder: function (state, action) {
            var _a;
            state.bidder = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
        setIsLoading: function (state, action) {
            var _a;
            state.isLoading = (_a = action === null || action === void 0 ? void 0 : action.payload) !== null && _a !== void 0 ? _a : null;
        },
    },
});
exports.setIsBidderVerified = (_a = slice.actions, _a.setIsBidderVerified), exports.setIsBidderRegistered = _a.setIsBidderRegistered, exports.setBidder = _a.setBidder, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;
