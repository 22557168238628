"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionDetailsComponent = void 0;
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var pro_duotone_svg_icons_1 = require("@fortawesome/pro-duotone-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var get_auction_1 = require("../api/calls/auction/get_auction");
var main_1 = require("../reducers/slices/main");
var gallery_1 = require("./gallery");
var loader_content_1 = require("./loader_content");
var AuctionDetailsComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    // const { auctionID } = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _o = (0, react_1.useState)(true), isLoading = _o[0], setIsLoading = _o[1];
    // Use this liveAuction to call the get_auction endpoint.
    // This will get the auction details including the images, documents, etc.
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _p = (0, react_1.useState)(), auction = _p[0], setAuction = _p[1];
    (0, react_1.useEffect)(function () {
        if (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id) {
            setIsLoading(true);
            (0, get_auction_1.get_auction)({
                entity_id: liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id,
            }).then(function (response) {
                setIsLoading(false);
                if (response.success) {
                    setAuction(response.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Oops!",
                        messages: [
                            "Something went wrong while we were getting the auction details.",
                            response.message,
                        ],
                    }));
                }
            });
        }
    }, [liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id]);
    return (react_1.default.createElement(loader_content_1.LoaderContent, { loading: isLoading, className: "flex flex-col md:flex-row h-full gap-2" },
        react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement(gallery_1.Gallery, { images: (_a = auction === null || auction === void 0 ? void 0 : auction.images) === null || _a === void 0 ? void 0 : _a.map(function (image, index) {
                    return {
                        src: image === null || image === void 0 ? void 0 : image.uploaded_file_url,
                        alt: image === null || image === void 0 ? void 0 : image.title,
                    };
                }) })),
        react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-2 p-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("div", { className: "text-black text-xl font-bold" }, auction === null || auction === void 0 ? void 0 : auction.title),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "xsmall", label: auction === null || auction === void 0 ? void 0 : auction.type, bold: true, textColor: "text-black" })),
                    react_1.default.createElement(doshx_controls_web_1.BadgeControl, { value: auction === null || auction === void 0 ? void 0 : auction.status, colors: {
                            backgroundColor: "bg-secondary",
                            valueColor: "text-white",
                        } })),
                react_1.default.createElement("div", { className: "max-h-[200px] overflow-y-auto" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "large", label: auction === null || auction === void 0 ? void 0 : auction.description, textColor: "text-black" })),
                react_1.default.createElement("div", { className: "h-1 bg-gray-200 py-1 px-4 rounded-full shadow-sm" }),
                react_1.default.createElement("div", { className: "flex gap-2 items-center" },
                    react_1.default.createElement("span", { className: "text-lg text-black" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_duotone_svg_icons_1.faCircleLocationArrow })),
                    react_1.default.createElement("div", { className: "cursor-pointer", onClick: function () {
                            window.open("https://maps.google.com?q=".concat(auction.location.latitude, ",").concat(auction.location.longitude), "_blank");
                        } },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", label: (_b = auction === null || auction === void 0 ? void 0 : auction.location) === null || _b === void 0 ? void 0 : _b.full_address, textColor: "text-black" }))),
                react_1.default.createElement("div", { className: "flex gap-2 items-center" },
                    react_1.default.createElement("span", { className: "text-lg text-black" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_duotone_svg_icons_1.faCircleCalendar })),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", label: "This auction will take place on ".concat(doshx_controls_web_1.Utilities.formatDateTime(auction === null || auction === void 0 ? void 0 : auction.date_from, //
                        "Do MMM YYYY, h:mm A"), " and will end on ").concat(doshx_controls_web_1.Utilities.formatDateTime(auction === null || auction === void 0 ? void 0 : auction.date_to, //
                        "Do MMM YYYY, h:mm A"), "."), textColor: "text-black", bold: true })),
                (auction === null || auction === void 0 ? void 0 : auction.registration_fee) != null && (auction === null || auction === void 0 ? void 0 : auction.registration_fee) > 0 && (react_1.default.createElement("div", { className: "flex gap-2 items-center" },
                    react_1.default.createElement("span", { className: "text-lg text-black" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_duotone_svg_icons_1.faMoneyBill })),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", label: "Registration fee: ".concat(doshx_controls_web_1.Utilities.formatMoney({
                            value: auction === null || auction === void 0 ? void 0 : auction.registration_fee,
                            decimalPlaces: 2,
                        })), textColor: "text-black" }))),
                react_1.default.createElement(doshx_controls_web_1.PillsControl, { size: "medium", pillSpacing: "justify-left", pills: [
                        {
                            visible: !!((_d = (_c = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _c === void 0 ? void 0 : _c.whatsapp) === null || _d === void 0 ? void 0 : _d.number),
                            label: "".concat((_f = (_e = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _e === void 0 ? void 0 : _e.whatsapp) === null || _f === void 0 ? void 0 : _f.number),
                            icon: free_brands_svg_icons_1.faWhatsapp,
                            colors: {
                                iconColor: "text-green-500",
                            },
                            onClick: function () {
                                var _a, _b;
                                window.open("https://wa.me/".concat((_b = (_a = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _a === void 0 ? void 0 : _a.whatsapp) === null || _b === void 0 ? void 0 : _b.number, "?text=Hello"));
                            },
                        },
                        {
                            visible: !!((_h = (_g = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _g === void 0 ? void 0 : _g.phone) === null || _h === void 0 ? void 0 : _h.number),
                            label: "".concat((_k = (_j = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _j === void 0 ? void 0 : _j.phone) === null || _k === void 0 ? void 0 : _k.number),
                            icon: pro_duotone_svg_icons_1.faPhone,
                            colors: {
                                iconColor: "text-blue-500",
                            },
                            onClick: function () {
                                var _a, _b;
                                window.open("tel:".concat((_b = (_a = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.number));
                            },
                        },
                        {
                            visible: !!((_l = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _l === void 0 ? void 0 : _l.email),
                            label: "".concat((_m = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _m === void 0 ? void 0 : _m.email),
                            icon: pro_duotone_svg_icons_1.faEnvelope,
                            colors: {
                                iconColor: "text-green-500",
                            },
                            onClick: function () {
                                var _a;
                                window.open("mailto:".concat((_a = auction === null || auction === void 0 ? void 0 : auction.contact_details) === null || _a === void 0 ? void 0 : _a.email));
                            },
                        },
                    ], colors: {
                        pills: {
                            shadowColor: "shadow-gray-200",
                            backgroundColor: "bg-gray-100",
                        },
                    } })))));
};
exports.AuctionDetailsComponent = AuctionDetailsComponent;
